import { Box, Grid, Stack, Typography } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

// components
import Hero from "../../utils/hero";
import Page from "../../utils/page";
import Section from "../../utils/section";
import theme from "../../utils/theme";
import useConfig from "../../utils/useConfig";

const ChiSiamoPage = () => {
  const siteMetadata = useConfig();
  const schema = [
    {
      "@context": "https://schema.org/",
      "@type": "Person",
      name: "Linda Mettifogo",
      jobTitle: "Web & Horeca sales",
      worksFor: { "@id": `${siteMetadata.siteUrl}/#organization` },
    },
    {
      "@context": "https://schema.org/",
      "@type": "Person",
      name: "Alessio Balenario",
      jobTitle: "Responsable de producción",
      worksFor: { "@id": `${siteMetadata.siteUrl}/#organization` },
    },
    {
      "@context": "https://schema.org/",
      "@type": "Person",
      name: "Omar Marcolongo",
      jobTitle: "Encargado de impresión",
      worksFor: { "@id": `${siteMetadata.siteUrl}/#organization` },
    },
  ];

  return (
    <Page
      lang="es"
      title="Horeca para bares, hoteles y restaurantes"
      description="Mettifogo Srl está especializada en la producción y comercialización de artículos personalizados para la restauración, como toallitas húmedas, geles desinfectantes monodosis, sobres de azúcar, bolsas para cubiertos, manteles individuales, bolsas para frituras y menús."
      type="AboutPage"
      schema={schema}
      image="/chi-siamo.jpg"
    >
      <Hero
        title="Desde 3 generaciones al servicio de nuestros clientes"
        description="Siempre buscamos nuevas ideas, nuevos objetivos para estar un paso por delante, teniendo en cuenta la tradición pero con una visión moderna y el conocimiento de las tendencias del momento."
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../../images/backgrounds/fragranza-menta.jpg"
            alt="Fragancia menta"
            layout="fullWidth"
          />
        }
        image={
          <StaticImage
            imgStyle={{ borderRadius: 16 }}
            src="../../images/team/mettifogo-tradizione-da-3-generazioni.jpg"
            alt="Desde 3 generaciones Mettifogo al servicio de los clientes"
            layout="fullWidth"
          />
        }
      />

      <Section>
        <Grid
          spacing={6}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} md={5}>
            <StaticImage
              imgStyle={{ borderRadius: 16 }}
              src="../../images/mettifogo-produttore-salviette-personalizzate.jpg"
              alt="Envases de protección"
              layout="fullWidth"
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Stack spacing={1}>
              <Typography variant="h3" component="h3">
                Quiénes somos
              </Typography>
              <Typography>
                Mettifogo, nacida como empresa de distribución de productos de
                confitería, se transforma a finales de los años 80, añadiendo un
                importante sector, el de la producción de artículos para el
                mundo horeca, hasta llegar a 1998, cuando inauguró la línea de{" "}
                <strong>toallitas húmedas desechables</strong> con diferentes
                tipos de sabores como <strong>toallitas de limón</strong>,{" "}
                <strong>toallitas de lime y canela</strong> y{" "}
                <strong>toallitas mojito</strong>.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3" component="h3">
              Nuestro equipo
            </Typography>
            <Typography>
              Trabajamos juntos con un procedimiento planificado garantizando a
              nuestros clientes el mejor servicio y valorando cada trabajo.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box mb={3}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/team/linda-mettifogo-vendita-horeca-web.jpg"
                alt="Linda Mettifogo"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Linda Mettifogo
            </Typography>
            <Typography>Web & Horeca sales</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box mb={3}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/team/alessio-balenario-responsabile-produzione.jpg"
                alt="Alessio Balenario"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Alessio Balenario
            </Typography>
            <Typography>Responsable de producción</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box mb={3}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/team/omar-macolongo-produzione.jpg"
                alt="Omar Marcolongo"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Omar Marcolongo
            </Typography>
            <Typography>Encargado de impresión</Typography>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Grid spacing={6} container justifyContent="space-between">
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/spedizione-rapida-horeca.jpg"
                alt="Envío rápido en toda Europa"
                layout="fullWidth"
              />
              <Typography variant="h3" component="h3">
                Envío rápido en toda Europa
              </Typography>
              <Typography>
                Gracias a la organización de nuestros medios de transporte y
                junto con la ayuda de los principales transportistas
                internacionales, logramos llegar a toda la{" "}
                <strong>península italiana</strong> y a toda{" "}
                <strong>Europa</strong>.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/made-in-italy.jpg"
                alt="Fabricación Made in Italy"
                layout="fullWidth"
              />

              <Typography variant="h3" component="h3">
                Fabricación Made in Italy
              </Typography>
              <Typography>
                Nuestras toallitas húmedas desechables son totalmente Made in
                Italy. Todos los productos elegidos son de origen italiana y de{" "}
                <strong>primera calidad</strong>, para poder mantener el{" "}
                <strong>estándar cualitativo</strong> del producto a lo largo de
                los años.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Section>
    </Page>
  );
};

export default ChiSiamoPage;
